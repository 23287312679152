.SelfWrap {
  height: auto;
}

.text-color {
  color: #131238;
  font-weight: bold;
}

.bg-btn {
  background-color: #0066b3;
}

.badge-box {
  background-color: #1B487B !important;
  font-size: 1rem !important;
  color: white !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.tableName {
  width: 200px;
  justify-content: center;
  padding: 20px 0;
}

.tablehaderWrap {
  justify-content: center;
  padding: 3px 0;
}

.tablewrap {
  justify-content: center;
  padding: 20px 0;
}

.btnwrap {
  justify-content: center;
}

.btn {
  width: 70px;
  padding: 0.2em 0.8em;
  color: #0066b3;
  border-radius: 30rem;
  border: 1px solid #0066b3;
  margin: 1px 3px;
}

.order:hover {
  background-color: #0066b3;
  color: #fff;
}

.del:hover {
  background-color: #ff4242;
  color: #fff;
}

.updateTime {
  border: 1px solid;
  width: max-content;
  margin-left: auto;
  margin-right: 0;
  margin-top: 13px;
  margin-bottom: 3px;
  border-radius: 50px;
  padding: 0 10px;
  font-size: 12px;
}

.red {
  color: #ff4242;
}

.green {
  color: #0fb992;
}

.tableHead-minHeight th>div {
  min-height: 50px !important;
}

.tableHead-minHeight th {
  min-width: 105px;
}

.disabled_btn {
  color: #aaaaaa !important;
}

.disabled_btn:hover {
  color: #aaaaaa !important;
  background-color: transparent !important;
}