/* RWD部分 */
.carsouelItem {
  min-height: 180px;
  background-image: url('../../static/image/carsouuel1.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.carsouelItem2 {
  min-height: 180px;
  background-image: url('../../static/image/財經要雯BANNER.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.carsouelItem3 {
  min-height: 180px;
  background-image: url('../../static/image/IP_Banner.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

/* @media screen and (min-width: 530px) {


  .carsouelItem3 {
    background-image: url('../../static/image/carsouuel3-mid.png');
    background-size: cover;
  }
} */
 /* PC裝置部分 */
@media screen and (min-width: 650px) {
  .carsouelItem {
    background-image: url('../../static/image/carsouuel1.png');
    background-size:cover;
  }

  .carsouelItem2 {
    background-image: url('../../static/image/財經要雯BANNER.png');
    background-size:contain;
  }

  .carsouelItem3 {
    background-image: url('../../static/image/IP_Banner-big.jpg');
    background-size: cover;
  }
}