/* @import 'helpers/variable.css'; */

.wrap {
  border-radius: 0px;
  overflow: hidden;
  display: flex;
  align-items: center;

  &--disabled {
    opacity: 0.4;
  }

  .input-wrap {
    position: relative;
    height: 40px;
    flex: 1 1;

    &__title {
      position: absolute;
      left: 5px;
      bottom: 3px;
      color: #eee;
      font-size: 10px;
    }

    &__input {
      width: 100%;
      height: 100%;
      

      :global {
        .rc-input-number-input-wrap {
          width: 100%;
          height: 100%;
          background: #fff;
          overflow: hidden;
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          .rc-input-number-input {
            width: 100%;
            height: 100%;
            border: 0;
            border-top: 1px solid #dcdcdc;
            border-bottom: 1px solid #dcdcdc;
            margin: 0;
            
            padding: 0;
            text-align: center;
            box-sizing: border-box;
            background: transparent;
          }
        }
      }
    }
  }

  .handler-wrap {
    flex: 1 1;
    box-sizing: border-box;
    /**border-radius: 0 5px 5px 0;*/
    background-color: #2c3644;
    display: flex;
    overflow: hidden;
    height: 40px;

    &__handler {
      flex-grow: 0;
      margin: 0;
      width: 40px;
      height: 40px;
      border:1px solid #dcdcdc;
      background-color: #fff;
      padding: 5px 0px;
      font-size: 25px;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;

      &:first-child {
        border-radius: 5px 0 0 5px;
      }
      &:last-child {
        border-radius: 0 5px 5px 0;
      }
    }
  }
}