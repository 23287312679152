.text-color {
  color: #00367c;
  font-weight: bold;
}

.container-left {
  text-align: start;
}

.wrap {
  padding-top: 10px;
}

.taskListTitle {
  background-image: url('/src/static/image/taskListTitle.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  -webkit-background-size: contain;
  -o-background-size: contain;
  -moz-background-size: contain;
  -ms-background-size: contain;
  height: 185px;
}

section {
  padding: 2px 10px;
  border-radius: 20px;
  display: table;
  background-color: rgb(240, 240, 58);
}

section:hover {
  padding: 2px 10px;
  border-radius: 20px;
  display: table;
  background-color: #373b44;
}

.Icon_key {
  background: orange;
  padding: 5px;
  border-radius: 50%;
  color: yellow;
}

.startGame-btn {
  color: #0066b3;
  background-color: #fff;
  border: 1px solid #0066b3;
  border-radius: 20px;
  min-width: 100px;
}

.startGame-btn:hover {
  color: #fff;
  background-color: #0066b3;
  border-radius: 20px;
}

.taskCol {
  padding: 10px 20px;
}

.taskItem {
  background-color: #c7f3ff;
  border-radius: 10px;
  min-height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cursor_pointer {
  cursor: pointer;
}

.taskFinished {
  background-color: #02487e;
  color: white;
}

.taskNotFinished {
  background-color: #ffffff;
}

.updateTime {
  border: 1px solid;
  width: max-content;
  margin-left: auto;
  margin-top: 13px;
  margin-bottom: 3px;
  margin-right: 0;
  border-radius: 50px;
  padding: 0 10px;
  font-size: 12px;
}

.finishTaskNumber {
  min-width: 50px;
  border-bottom: 1px solid black;
  text-align: center;
  display: inline-block;
}

.badge-box {
  background-color: #1B487B !important;
  font-size: 1rem !important;
  color: white !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
}