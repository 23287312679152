/* Modal */

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--bs-body-bg);
  z-index: 999;
  overflow-y: scroll;
}

/* Don't scroll parent component  */
.GuideModal__Body--open {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
}

.order-modal {
  background-color: #123051;
  opacity: 0.95 !important;
}

.modalTitle {
  text-align: center;
  flex: 1 0 auto;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 55px;
  font-size: larger;
  font-weight: bold;
  font-size: 24px;
}

.modalLeft {
  position: absolute;
  left: 15px;
  top: 15px;
}

.modalCloseButton {
  /* display: inline-block; */
  height: 35px;
  width: 35px;
  border-radius: 50%;
  font-weight: 600;
  background-color: #ffde00;
  border: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalCloseButtonFont {
  font-size: larger;
  font-weight: bolder;
}

.each-slide-wrap {
  margin: auto;
}

.each-slide-effect>div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.each-slide-effect span {
  position: absolute;
  padding: 20px;
  text-align: left;
}

.each-slide-wrap img {
  width: 100%;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}

.mainModalTitle {
  background-color: #0b3481;
  position: relative;

}

.titleBlue {
  color: #0b3481;
}

.submitSection {

  color: #ffffff;
  padding: 8px 0 8px 5px;
  font-size: larger;
  font-weight: bold;

}

.section1Bg {
  background-color: #1a66b9;
}

.section2Bg {
  background-color: #0b3481;
}

.transition {
  transition: 0.5s;
}

.hangleArrowRotate {
  transform: rotate(90deg);
}

.submitContent {
  background-color: #eee;
  background-image: url(./../../static/image/App_BG.png);
  background-position: left 50% top 45%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 45% auto;
  -webkit-background-size: 45% auto;
  -o-background-size: 45% auto;
  -moz-background-size: 45% auto;
  -ms-background-size: 45% auto;
}

.flex-overlay {
  min-height: 100vh;

}

.submitBottomBtnCheck {
  background-color: #f98500;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
}

.submitBottomBtn {
  border: 1px solid #d8d8d8;
  border-radius: 10px;
  background-color: #fff;
  cursor: pointer;
}

.alertParagraph {
  color: #ff0000;
  font-weight: bold;
}

.personalInfoCheck {
  width: 20px;
  height: 20px;
  border-radius: 10px;
}

.personalInfo {
  color: #0b3481;
  font-size: 20px;
  text-decoration: underline;
}

.errorInput {
  border: 1px solid #ff0000;
}

.schoolSelect input {
  width: 100%;
  background-color: #fff;
  border-radius: 0.7rem !important;
  padding-left: 10px !important;
  /* padding: 0.375rem 0; */
  border: solid transparent;
}


.boxShadowForInput {
  box-shadow: 4px 4px 0.25rem rgb(0 0 0 / 15%);
}

/* .schoolSelect .disabled input {
  background-color: #d8d8d8 !important;
  cursor: not-allowed;
  color: #696969;
} */

.disabled {
  background-color: #d5d5d5 !important;
  cursor: not-allowed;
  color: #202020;
}

.modalTitle {}

.errmsg {
  color: red;
}

.copyShareButton {
  font-size: 14px;
  color: #fff;
  padding: 5px 8px;
  border-radius: 5px;
  background-color: #315496;
  cursor: pointer;
}

.OpenAccount {
  width: 100px;
  background-color: #FF7518;
  justify-content: center;
  align-items: center;
}

.bonus {
  font-size: 20px;
  color: #0b3481;
}

.disabledOverlay {
  position: relative;
}

.disabledOverlay::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5); /* 半透明覆盖层 */
  cursor: not-allowed;
  z-index: 10; /* 确保覆盖在 SelectSearch 组件之上 */
}

.disabled {
  color: #acb1b8; 
  background-color: #f2f2f2;  
  pointer-events: none;  
  cursor: not-allowed;  
}



.disabled div,
.disabled input {
  background-color: #d5d5d5 !important;
  color: #202020 !important;
  cursor: not-allowed;
}


.btn-disabled {
  background-color: grey !important;
  border-color: grey !important;
  color: #fff !important;
  cursor: not-allowed;
}