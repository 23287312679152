.text-color {
  color: #131238;
  font-weight: bold;
}

.wrap {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 5px;
  /* max-width: 375px; */
  text-align: left;
}

.tip {
  list-style-type: none;
  font-weight: bolder;
  padding: 5px 0;
}

.IpBanner {
  height: 200px;
  background: url("/src/static/image/IP_Banner.jpg") no-repeat center;
  background-size: cover;
}

.carsouelItem {
  min-height: 180px;
  background-image: url('../../static/image/carsouuel1.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.carsouelItem2 {
  min-height: 180px;
  background-image: url('../../static/image/財經要雯BANNER.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.carsouelItem3 {
  min-height: 180px;
  background-image: url('../../static/image/carsouuel1.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}


/* 此處為PC */
@media (min-width: 1920px) {
  .IpBanner {
    width: 1920px;
    height: 200px; /* 如果需要，更新高度 */
    background-image: url("/src/static/image/IP_Banner-big.jpg");
    background-size:cover;
  }
}

.carsouelItem {
  background-image: url('../../static/image/carsouuel1.png');
  background-size:cover;
}
.carsouelItem2 {
  background-image: url('../../static/image/財經要雯BANNER.png');
  background-size:contain;
}
.carsouelItem3 {
  background-image: url('../../static/image/IP_Banner-big.jpg');
  background-size:cover;
}
.opacity-60 {
  opacity: 0.6;
}

.btn-close {
  background: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27  d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e") center/1em auto no-repeat;
  background-color: #ffb300 !important;
  opacity: 1 !important;
  border-radius: 50% !important;
  position: absolute !important;
  top: 20px !important;
  right: 20px !important;
}

.loginWayHeight {
  height: 75px;
}

.buttonFrag {
  text-decoration: underline;
  cursor: pointer;
}

.fs-10px {
  font-size: 10px;
}

.list {
  padding: 3px 0;
}

.inputVerify {
  margin-right: 5px;
}

.inputVerify .reactinputverificationcode__item {
  padding: 10px;
}

.btn-verify {
  background: #197ed0 !important;
  font-weight: bold !important;
}

.btn-verify:hover {
  background: #197ed0 !important;
  opacity: 0.5;
}

.btn-verify:disabled {
  background: #b5b5b5 !important;
  border: 0;
  color: #fff;
  opacity: 1;
}

.verifypass {
  color: white !important;
  background: linear-gradient(to bottom,
      #A3C200 0%,
      #A3C200 100%) !important;
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  font-weight: bold !important;
  cursor: auto !important;
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
}

.thirdPartyBlock {
  border: 1px dashed #737373;
  border-radius: 10px;
  padding: 16px 21px 10px;
}

.hyperLinkText {
  color: #197ed0 !important;
  text-decoration: underline;
  cursor: pointer;
}

.IpLogoBg {
  background-color: #ffffff;
  height: 80px;
  padding: 20px;
}

.IpLogo {
  /* background-color: orange; */

}

.loginBtn,
.loginBtn:hover {
  background-color: #f68b1e;
  border: 0;
  border-radius: 8px;
}

.lockIcon {
  left: 10px;

}

.eyeIcon {
  right: 10px;
}

.passwordInput {
  padding-left: 45px;
  padding-right: 35px;
  min-height: inherit;
}

/* 
.loginBtn:hover {
  background-color: #f68b1e;
  border: 0;
  border-radius: 8px;
} */