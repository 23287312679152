/* Modal */

/* Don't scroll parent component  */
.AlertModel__Body--open {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
}

.mainfont {
  font-size: 18px;
  text-align: left;
  font-weight: bold;
}

.subfont {
  font-size: 14px;
  text-align: left;
  font-weight: bold;
}

.order-modal {
  background-color: #123051;
  opacity: 0.95 !important;
}

.alertmodelHearder {
  height: 50px;
  width: auto;
}

.modalTitle {
  text-align: center;
  flex: 1 0 auto;
  color: #3291fe;
  padding: 12px;
  font-size: larger;
  font-weight: bold;
}

.modalRight {
  position: absolute;
  right: 10px;
  top: 12px;
}

.modalCloseButton {
  /* display: inline-block; */
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: 600;
  border: 1px solid #b8b8b8;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalCloseButtonFont {
  font-size: larger;
  font-weight: bolder;
}

.contentWrap {
  height: 90px;
  padding: 10px;
  text-align: center;
}

.contentWrapForArticle {
  padding: 10px;
  text-align: center;

}

.contentArticle {
  max-height: 40vh;
  overflow-y: auto;
}

.contentArticleTitle {
  text-align: center;
}

.contentArticleParagraph {
  text-align: justify;
  text-align-last: left;
  line-height: 1.5;
}

.alertmodalContent {
  display: flex;
  padding: 0px 30px;
}

.alertmodalContentColunn {
  display: flex;
  flex-direction: column;
  padding: 0px 30px;
}

.alertmodelFoot {
  height: 50px;
  width: auto;
}

.button-group {
  display: flex;
  padding: 0px 20px;
}

.notice_btn {
  padding: 10px 20px;
  border: 1px solid #b8b8b8;

}

.submitBtn {
  border: 0;
  padding: 10px 20px;
  background-image: linear-gradient(to bottom, #ff5d14, #faa619);
  border-radius: 30px;
  color: #fff;
}