.text-color {
  color: #131238;
  font-weight: bold;
}

.badge-box {
  background-color: #1B487B !important;
  font-size: 1rem !important;
  color: white !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.expander {
  width: 10px !important;
}

.tablewrap {
  padding: 10px 0;
  /* width: 200px; */
}

.buy {
  color: #ff4242;
  font-weight: 800;
}

.sell {
  color: #0fb992;
  font-weight: 800;
}

.del-btn {
  display: inline-block;
  width: 60px;
  /* padding: 0.2em 0.8em; */
  color: #0066b3;
  border-radius: 30rem;
  border: 1px solid #0066b3;
  margin: 1px 3px;
  justify-content: center;
}

.del-btn:hover {
  color: #fff;
  background-color: #ff4242;
  border-radius: 20px;
}

.updateTime {
  border: 1px solid;
  width: max-content;
  margin-left: auto;
  margin-right: 0;
  margin-top: 13px;
  margin-bottom: 3px;
  border-radius: 50px;
  padding: 0 10px;
  font-size: 12px;
}

.paginatioWrap {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  color: #0066b3;
  font-size: 1.3rem;
}

.tableHead-minHeight th>div {
  min-height: 50px !important;
}

.min_width_55px {
  min-width: 55px !important;
}