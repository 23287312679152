$primary: #0066b3;
$secondary: gray;
$info: #29c5d7;
$light: #fff;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "info": $info,
  "light": $light,
);

@import "../../node_modules/bootstrap/scss/bootstrap";
