:root {
  --table__sub-components-bg: #fdf5e6;
  --primary-color: #00367c;
  --bs-primary: #00367c;

}

#root {
  max-width: 100vw;
  overflow: hidden;
}

.App {

  text-align: center;
  min-height: 100vh;

  background-image: url(./static/image/App_BG.png) !important;
  background-position: left 50% top 45vh !important;

  background-repeat: no-repeat !important;

  background-attachment: fixed !important;

  background-size: 45% auto !important;

  -webkit-background-size: 45% auto !important;

  -o-background-size: 45% auto !important;

  -moz-background-size: 45% auto !important;

  -ms-background-size: 45% auto !important;
  /* background-color: #e4ebf0; */
  /* ReactInputVerificationCode */
  --ReactInputVerificationCode-itemWidth: 2.3rem;
  --ReactInputVerificationCode-itemHeight: 2.3rem;
}

/* ReactInputVerificationCode */
.ReactInputVerificationCode__container {
  width: 55vw !important;
  max-width: 240px !important;
}

.ReactInputVerificationCode__item {
  background: #fff !important;
}

.page-background {
  /* background-image: url(./static/image/bg_main.png); */
  background-position: center 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  -ms-background-size: cover;
  /* background-color: #e4ebf0; */
}

.page-banner {
  background-image: url(./static/image/trad_banner.jpg);
  position: relative;
}

/* Navigation */
@media only screen and (max-width: 767px) {
  .btn-radius {
    border-radius: 25px !important;
    min-width: 55px;
  }

  .mobile-hide {
    display: none;
  }

  .nav-rounded {
    padding: 0.275rem 0.35rem !important;
  }

  .GuideModal {
    position: absolute;
    background-color: #021d3b;
    top: 55px;
    left: 20px;
    right: 20px;
    color: #fff;
    margin: 1rem auto;
    border-radius: 2rem;
    border: 0.2rem solid #3291fe;
    opacity: 1;
    max-width: 767px;
  }
}

@media only screen and (min-width: 768px) {
  .nav-item {
    padding: 2px 0 !important;
  }

  .desktop-hide {
    display: none;
    padding: 0 !important;
  }

  .desktop-w {
    margin: auto !important;
    padding: 15px 0;
  }

  .nav-rounded {
    border-radius: 30rem !important;
  }

  .nav-wrap {
    width: 98vw !important;
    min-width: 7.5vw;
  }

  .GuideModal {
    position: absolute;
    background-color: #021d3b;
    top: 55px;
    left: 20px;
    right: 20px;
    color: #fff;
    margin: 1rem auto;
    border-radius: 2rem;
    border: 0.2rem solid #3291fe;
    opacity: 1;
    max-width: 1024px;
  }
}

@media only screen and (min-width: 990px) {
  .inputWarp {
    min-width: 285px;
  }
}

@media only screen and (min-width: 1024px) {
  .desktop-hide {
    display: none;
  }

  .desktop-w {
    margin: auto !important;
  }

  .nav-rounded {
    border-radius: 30rem !important;
    min-width: 7.5vw;
  }

  .GuideModal {
    position: absolute;
    background-color: #021d3b;
    top: 55px;
    left: 20px;
    right: 20px;
    color: #fff;
    margin: 1rem auto;
    border-radius: 2rem;
    border: 0.2rem solid #3291fe;
    opacity: 1;
    max-width: 1200px;
  }
}

.btn-outline-primary {
  font-weight: bold !important;
  color: #00367c !important;
  border-color: #a29a9a !important;
}

/* .active {
  color: white !important;
  background-color: #0066b3 !important;
  font-weight: bold !important;
} */
.btn-outline-primary:checked {
  color: white !important;
  background-color: #0066b3 !important;
  font-weight: bold !important;
}

.btn-outline-primary:hover {
  color: white !important;
  background-color: #0066b3 !important;
  font-weight: bold !important;
}

.page-item.active .page-link {
  background-color: #0066b3 !important;
  border-color: #0066b3 !important;
  color: #fff !important;
  z-index: 3 !important;
}

/* page-account */
.page-account {
  padding: 10px;
}

.container-left {
  text-align: start;
}

.marginy-3px {
  margin: 3px 0;
}

.table-count {
  min-width: 50px;
  font-weight: 500;
  padding-right: 0.5em;
  padding-left: 0.5em;
  border-radius: 10rem;
}

/* Modal */
.Modal {
  position: absolute;
  top: 1px;
  left: 20px;
  right: 20px;
  color: #fff;
  border: 0.2rem solid #3291fe;
  background-color: #021d3b;
  max-width: 450px;
  margin: 1rem auto;
  border-radius: 2rem;
  opacity: 1;
}

.GuideModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #123051;
  opacity: 0.98;
  z-index: 999;
  overflow-y: scroll;
}

.CampaignSubmitModelOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e4ebf0;
  opacity: 1 !important;
  transform: translateY(0px) !important;
  z-index: 999;
  overflow-y: scroll;
  background-image: url(./static/image/App_BG.png) !important;
  background-position: left 50% top 45% !important;

  background-repeat: no-repeat !important;

  background-attachment: fixed !important;

  background-size: 45% auto !important;

  -webkit-background-size: 45% auto !important;

  -o-background-size: 45% auto !important;

  -moz-background-size: 45% auto !important;

  -ms-background-size: 45% auto !important;

}

.CampaignSubmitModelOverlay>.ReactModal__Content {
  inset: unset !important;
  padding: 0 !important;
  position: relative !important;
  border: none !important;
  border-radius: 0 !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;

}

.CampaignSubmitModelOverlayWhite {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
  opacity: 1 !important;
  transform: translateY(0px) !important;
  z-index: 999;
  overflow-y: scroll;
  background-image: url(./static/image/App_BG.png) !important;
  background-position: left 50% top 45% !important;

  background-repeat: no-repeat !important;

  background-attachment: fixed !important;

  background-size: 45% auto !important;

  -webkit-background-size: 45% auto !important;

  -o-background-size: 45% auto !important;

  -moz-background-size: 45% auto !important;

  -ms-background-size: 45% auto !important;

}

.CampaignSubmitModelOverlayWhite>.ReactModal__Content {
  inset: unset !important;
  padding: 0 !important;
  position: relative !important;
  border: none !important;
  border-radius: 0 !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  background-color: transparent !important;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #a2a2a296;
  opacity: 1;
  z-index: 999;
  overflow-y: scroll;
}

/* Don't scroll parent component  */
.ReactModal__Body--open {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;

}

.order-modal {
  background-color: #123051;
  opacity: 0.95 !important;
}

.modalTitle {
  text-align: center;
  flex: 1 0 auto;
  color: #3291fe;
  padding: 12px;
  font-size: larger;
  font-weight: bold;
  border-bottom: 0.2rem solid #3291fe;
}

.modalRight {
  position: absolute;
  right: 10px;
  top: 12px;
}

.modalCloseButton {
  /* display: inline-block; */
  height: 35px;
  width: 35px;
  border-radius: 50%;
  font-weight: 600;
  background-color: #ffde00;
  border: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalCloseButtonFont {
  font-size: larger;
  font-weight: bolder;
}

.modalContent {
  padding: 10px;
  background-color: #02152a !important;
}

.button-group {
  display: flex;
  padding: 3px 20px;
}

.col-form-label {
  padding-left: 10px;
  text-align: start !important;
  font-size: large !important;
  font-weight: 600 !important;
}

.form-control-plaintext {
  background-color: #fff !important;
  border-radius: 0.7rem !important;
  padding-left: 10px !important;
}

/* .form-control:focus { */
/* background-color: yellow !important; */
/* box-shadow: 0 0 0 0.1rem black !important;
} */

.order-button {
  text-align: center;
  background-color: #ffffff !important;
  color: black !important;
  border-radius: 10px !important;
  margin: 0 10px;
  border: 1px solid #ccc !important;
}

/* .order-button:hover {
  background-color: #ffde00 !important;
} */

.order-button-cancel {
  text-align: center;
  background-color: #ffffff !important;
  color: black !important;
  border-radius: 10px !important;
  margin: 0 10px;
  border: 1px solid #ccc !important;
}

/* .order-button-cancel:hover {
  background-color: red !important;
} */

.modalFoot {
  padding: 20px;
}

.five-section {
  background-color: #fff;
  padding: 5px;
  border-radius: 10px;
  min-height: 200px;
}

.five-group {
  /* padding: 5px; */
  background-color: #fff;

}

.fiveBuy {
  background: linear-gradient(101deg,
      #ff4242 0%,
      #ff4242 78%,
      #fff 80%,
      #fff 100%) !important;
  padding: 4px;
  width: 40px;
  border: 0 !important;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  font-weight: bolder;
}

.fiveSell {
  background: linear-gradient(80deg,
      #0fb992 0%,
      #0fb992 78%,
      #fff 80%,
      #fff 100%) !important;
  padding: 4px;
  width: 40px;
  border: 0 !important;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  font-weight: bolder;
}

.fivePrice {
  text-align: center !important;
  max-width: 90px !important;
  padding: 2px 15px;
  min-width: 76px;
  border: 1px solid #b7b7b7;
  background-color: #eee;
  border-radius: 10px;
  font-weight: bolder;
  cursor: pointer;
}

.fiveQuantity {
  border: 0 !important;
  text-align: center !important;
  /* border-left: 2px solid #a29a9a !important; */
  background-color: white !important;
  /* border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important; */
  max-width: 90px !important;
  padding: 0px !important;
  font-weight: bolder;
}

.toastContain {
  text-align: left;
}

.footToast {
  z-index: 9999;
  position: fixed;
  bottom: 0;
  right: 0;
  text-align: left;
  height: 70px;
  background-color: white;
  width: 1px;
}

.ShareModal {
  /* height: 550px !important; */
  position: absolute;
  top: 55px;
  left: 20px;
  right: 20px;
  color: #fff;
  max-width: 450px;
  margin: 1rem auto;
  border-radius: 2rem;
  border: 5px solid #ffbf00;
  opacity: 1;
  background: linear-gradient(to bottom,
      #ffbf00 0%,
      #ffa03c 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.sharemodalFoot {
  background-color: #fff;
  border-bottom-right-radius: 2rem;
  border-bottom-left-radius: 2rem;
  color: #0066b3;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.guidemodalFoot {
  background-color: #fff;
  border-bottom-right-radius: 2rem;
  border-bottom-left-radius: 2rem;
  color: #0066b3;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.AlertModel {
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  left: 20px;
  right: 20px;
  color: #fff;
  max-width: 450px;
  height: 200px;
  margin: 1rem auto;
  border-radius: 2rem;
  opacity: 1;
  background: white;
}

.TeamJoinModel {
  overflow: hidden;
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  left: 20px;
  right: 20px;
  color: #000000;
  max-width: 450px;
  margin: 1rem auto;
  border-radius: 2rem;
  opacity: 1;
  background: white;
  border: 2px solid #2c4a8a;
}

.alertmodelFoot {
  background-color: #fff;
  border-bottom-right-radius: 2rem;
  border-bottom-left-radius: 2rem;
  color: #0066b3;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.alert-button {
  padding: 6px 24px 6px 24px;
  border-radius: 25px !important;
  border: solid 1px #fff !important;
  background-color: #f68b1e !important;
  /* color: #a1a5a8 !important; */
  text-align: center;
  margin: 0 20px;
}

.alert-button:hover {
  background-color: #3291fe !important;
  opacity: 0.8;
  border: 1px solid #3291fe !important;
  color: #fff !important;
  border-radius: 20px !important;
}

.alert-button-cancel {

  padding: 6px 24px 6px 24px;
  border-radius: 25px !important;
  border: solid 1px #a1a5a8 !important;
  background-color: #fff !important;
  color: #a1a5a8 !important;
  text-align: center;
  margin: 0 20px;

}

/* .alert-button-cancel:hover {
  background-color: #cf1a1a !important;
  border: 1px solid #cf1a1a !important;
  border-radius: 20px !important;
  margin: 0 20px;
  opacity: 0.8;
  border: 0 !important;
} */

.ver {
  font-size: 10px;
  bottom: 3px;
  position: absolute;
  right: 3px;
  background-color: transparent !important;
  min-height: 0 !important;

}

/* react-bootstrap modal*/
.modal-content {
  border-radius: 25px !important;
  overflow: hidden;
  border: 2px solid #2c4a8a !important
}

.modal-header {
  border: 0 !important;
  overflow: hidden;
}

.modal-body {
  text-align: center;
}

.modal-footer {
  border: 0 !important;
  justify-content: center !important;
}

.btn-close {
  width: 28px !important;
  height: 28px !important;
  background: none !important;
  position: relative;
  background-image: url('./static/image/Esc.png') !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  /* background: transparent url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27  d=%27M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z%27/%3e%3c/svg%3e") center/1em auto no-repeat; */

  opacity: 1 !important;
  border-radius: 50% !important;

}


.btn-close:hover {
  opacity: 0.7 !important;
  border-radius: 50% !important;
}

.modal-rounded {
  border-radius: 30rem !important;
}

/* react-table sub-table*/
.subrow {
  --bs-table-bg: var(--table__sub-components-bg);
  --bs-table-striped-bg: var(--table__sub-components-bg);
  --bs-table-active-bg: var(--table__sub-components-bg);
}

#googleSign>button>span {
  display: none !important;
}

#googleSign>button>div {
  margin: 0 !important;
  width: 100% !important;
  height: 100% !important;
}

#googleSign>button {
  width: 100% !important;
  height: 100% !important;
}

#googleSign>button>div>svg {
  width: 30px;
  height: 30px;
}

.errorInput {
  border: 1px solid red;

}

.backGround-Buy {
  background-color: #f8c7c7;
  background-image: url(./static/image/App_BG_Red.png) !important;
  background-position: left 50% top 50vh !important;

  background-repeat: no-repeat !important;

  background-attachment: fixed !important;

  background-size: 45% auto !important;

  -webkit-background-size: 45% auto !important;

  -o-background-size: 45% auto !important;

  -moz-background-size: 45% auto !important;

  -ms-background-size: 45% auto !important;
}

.backGround-Sell {
  background-color: #b7dbc3;
  background-image: url(./static/image/App_BG_Green.png) !important;
  background-position: left 50% top 50vh !important;

  background-repeat: no-repeat !important;

  background-attachment: fixed !important;

  background-size: 45% auto !important;

  -webkit-background-size: 45% auto !important;

  -o-background-size: 45% auto !important;

  -moz-background-size: 45% auto !important;

  -ms-background-size: 45% auto !important;
}

.backGround-Normal {
  background-color: rgb(238, 238, 238);
  background-image: url(./static/image/App_BG.png) !important;
  background-position: left 50% top 50vh !important;

  background-repeat: no-repeat !important;

  background-attachment: fixed !important;

  background-size: 45% auto !important;

  -webkit-background-size: 45% auto !important;

  -o-background-size: 45% auto !important;

  -moz-background-size: 45% auto !important;

  -ms-background-size: 45% auto !important;
}

.order_btn_buy {
  border-radius: 8px;
  border: solid 1px #a1a5a8;
  background-color: #f00001 !important;
  color: rgb(255, 255, 255) !important;
}

.order_btn_sell {
  border-radius: 8px;
  border: solid 1px #a1a5a8;
  background-color: #4fad31 !important;
  color: rgb(255, 255, 255) !important;
}

.inputDisable {
  background-color: #d8d8d8 !important;
  cursor: not-allowed !important;
}


.popup_header {
  background-color: #123051;
}

.padding_for_carsouelBlock {
  height: 55px;
}

.ReactModal__Overlay {
  opacity: 0;
  transform: translateY(-60px);
  transition: all 300ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transform: translateY(0px);
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  transform: translateY(-60px);
}