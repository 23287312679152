.wrap {
  display: flex;
  font-size: 18px;


}

.dayTradingBox {
  width: 38px;
  height: 38px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: #ff8500;
  border: 1px solid #fff;
}

.dayTradingFont {
  font-size: 10px;
  color: #fff;
}

.min-36px {
  min-width: 36px;
}