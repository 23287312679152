/* Modal */

/* Don't scroll parent component  */
.AlertModel__Body--open {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
}

.mainfont {
  font-size: 18px;
  text-align: left;
  font-weight: bold;
}

.subfont {
  font-size: 14px;
  text-align: left;
  font-weight: bold;
}

.order-modal {
  background-color: #123051;
  opacity: 0.95 !important;
}

.alertmodelHearder {
  height: 50px;
  width: auto;

}

.modalTitle {
  text-align: center;
  flex: 1 0 auto;
  color: #ffffff;
  padding: 12px;
  font-size: larger;
  font-weight: bold;
}

.errmsg {
  color: red;
  text-align: left;
  font-weight: bold;
}

.modalRight {
  position: absolute;
  right: 10px;
  top: 12px;
}

.modalCloseButton {
  /* display: inline-block; */
  height: 35px;
  width: 35px;
  border-radius: 50%;
  font-weight: 600;
  background-color: #ffde00;
  border: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalCloseButtonFont {
  font-size: larger;
  font-weight: bolder;
}

.contentWrap {
  height: 90px;
  padding: 10px;
  text-align: center;
}

.contentWrapForArticle {
  padding: 10px;
  text-align: center;

}

.contentArticle {
  max-height: 40vh;
  overflow-y: auto;
}

.contentArticleTitle {
  text-align: center;
}

.contentArticleParagraph {
  text-align: justify;
  text-align-last: left;
  line-height: 1.5;
}

.alertmodalContent {
  display: flex;
  padding: 0px 30px;
}

.alertmodalContentColunn {
  display: flex;
  flex-direction: column;
  padding: 0px 30px;
}

.alertmodelFoot {
  height: 50px;
  width: auto;
}

.button-group {
  display: flex;
  padding: 0px 20px;
}

.submitBtn {
  border: 0;
  background-image: linear-gradient(to bottom, #ff5d14, #faa619);
  border-radius: 30px;
}

.hyperLinkText {
  color: #197ed0 !important;
  text-decoration: underline;
  cursor: pointer;
}

.lockIcon {
  left: 10px;

}

.eyeIcon {
  right: 10px;
}

.passwordInput {
  padding-left: 45px;
  padding-right: 35px;
  min-height: inherit;
}