/* Modal */

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #123051;
  opacity: 0.98;
  z-index: 999;
  overflow-y: scroll;
}
/* Don't scroll parent component  */
.ReactModal__Body--open {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
}

.order-modal {
  background-color: #123051;
  opacity: 0.95 !important;
}
.modalTitleWrap {
  height: 70px;
}
.modalTitle {
  text-align: center;
  flex: 1 0 auto;
  color: #3291fe;
  padding: 12px;
  font-size: larger;
  font-weight: bold;

  position: relative;
  bottom: 90px;

  /* border-bottom: 0.2rem solid #3291fe; */
}

.modalRight {
  position: absolute;
  right: 10px;
  top: 12px;
}
.modalCloseButton {
  /* display: inline-block; */
  height: 35px;
  width: 35px;
  border-radius: 50%;
  font-weight: 600;
  background-color: #000;
  border: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modalCloseButtonFont {
  font-size: larger;
  font-weight: bolder;
  color: #fff;
}

.modalContent {
  justify-content: center;
  display: flex;
}
.sharebtn {
  padding: 0 0 20px 0;
  color: #fff;
}
textarea {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  width: 100%;
}
.shareText {
  color: #000;
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  padding-bottom: 25px;
}
.text-green {
  color: #fff;
  font-size: small;
  background-color: #07b53b;
  text-align: center;
}
.text-lightblue {
  color: #fff;
  font-size: small;
  background-color: #229bca;
  text-align: center;
}
.text-black {
  color: #000;
  font-size: small;
}
.wrap {
  padding: 15px 0px 5px 0;
}
.shareurlText {
  padding: 0 3vw;
  border: 2px solid #000;
  margin: auto;
  overflow: scroll;
  color: #000;
  font-weight: bold;
}
.copyurl {
  border-radius: 20px;
  background-color: #000;
  color: #fff;
  padding: 6px;
  width: 120px;
  margin: auto;
}
.copyurl:hover {
  border-radius: 20px;
  background-color: #000;
  color: #fff;
  padding: 6px;
  width: 120px;
  margin: auto;
  opacity: 0.8;
}
.icon {
  padding: 0 5px;
}
.flex-wrap{
  flex-wrap: wrap;
}