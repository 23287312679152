@media (max-width: 568px) {
  .lable-w {
    min-width: 26vw !important;
    text-align: right !important;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 1px;
  }

  .form-selece-w {
    display: inline-block;
    width: calc((100% - 26vw - 0.96rem)/2);
    max-width: 95px;
    font-size: 0.8rem;
    height: 36px;
    border-top: 0;
    border-left: 0;
    border-right: 1px solid rgb(233, 233, 233);
    border-bottom: 1px solid rgb(233, 233, 233);
    box-shadow: 4px 4px 0.25rem rgb(0 0 0 / 15%);
    -webkit-appearance: none;
    -webkit-box-shadow: 4px 4px 0.25rem rgb(0 0 0 / 15%);
  }

  .selecterdash {
    margin-top: 5px;
    padding: 0 0.25rem;
  }
}

@media (min-width: 568.1px) and (max-width: 767.99px) {
  .lable-w {
    width: 26vw !important;
    text-align: right !important;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 3px;
  }

  .form-selece-w {
    display: inline-block;
    width: 95px;
    font-size: 0.8rem;
    height: 36px;
    border-top: 0;
    border-left: 0;
    border-right: 1px solid rgb(233, 233, 233);
    border-bottom: 1px solid rgb(233, 233, 233);
    box-shadow: 4px 4px 0.25rem rgb(0 0 0 / 15%);
    -webkit-appearance: none;
    -webkit-box-shadow: 4px 4px 0.25rem rgb(0 0 0 / 15%);
  }

  .selecterdash {
    margin-top: 5px;
    padding: 0 0.25rem;
  }
}

@media (min-width: 768px) {
  .lable-w {
    min-width: 110px !important;
    text-align: right !important;
    font-size: 16px;
    font-weight: 700;
  }

  .form-selece-w {
    display: inline-block;
    width: 95px;
    font-size: 16px;
    height: 36px;
    border: 1px solid transparent;
    box-shadow: 1px 1px 0.125rem rgb(0 0 0 / 15%);
    -webkit-appearance: none;
    -webkit-box-shadow: 1px 1px 0.125rem rgb(0 0 0 / 15%);
  }

  .selecterdash {
    margin-top: 5px;
    padding: 0 0.25rem;
  }
}

@media (min-width: 1000px) {
  .lable-w {
    min-width: 85px !important;
    text-align: right !important;
    font-size: 15px;
    font-weight: 700;
    padding: 10px 3px !important;
  }

  .form-selece-w {
    display: inline-block;
    width: 95px;
    border: 1px solid transparent;
    height: 36px;
    box-shadow: 1px 1px 0.125rem rgb(0 0 0 / 15%);
    -webkit-appearance: none;
    -webkit-box-shadow: 1px 1px 0.125rem rgb(0 0 0 / 15%);
  }

  .selecterdash {
    margin-top: 5px;
    padding: 0 4px;
  }
}

.text-color {
  color: #131238;
  font-weight: bold;
}

.badge-box {
  background-color: #1B487B !important;
  font-size: 16px !important;
  color: white !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.selectWarp {
  display: flex;
}

.container-left {
  text-align: start;
  max-width: 1200px;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
  border-left: 1px solid #dee2e6 !important;
}

.rounded-lg {
  border-radius: 1.5rem !important;
  padding: 0px 10px;
}

.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}

.text-blue-light {
  color: #0066b3 !important;
}

.toggleBtn {
  width: 69px;
  border-radius: 5px;
  margin: auto;
  font-size: 16px;
  padding: 3px 10px;
}

.toggleBtn-active {
  color: white !important;
  background-color: #0066b3 !important;
  font-weight: bold !important;
}

.search-btn {
  margin: 0px 20px;
  border-radius: 8px;
  border: 0;
  background-color: #0066b3 !important;
  max-height: 55px;
}

.search-btn:hover {
  margin: 0px 20px;
  border-radius: 8px;
  background-color: #065b79 !important;
}

.download-btn {
  margin-left: 5px;
  /* box-shadow: 3px 4px 0px 0px #1564ad; */
  background: linear-gradient(to bottom, #0066b3 5%, #0066b3 100%);
  background-color: #0066b3;
  border-radius: 5px;
  border: 1px solid #0066b3;
  display: inline-block;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  padding: 3px 12px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #528ecc;
  color: #fff;
}

.download-btn:hover {
  background: linear-gradient(to bottom, #0066b3 5%, #0066b3 100%);
  background-color: #378de5;
}

.list-dot {
  list-style-type: none;
}

.action-w {
  width: 50px;
}

.moreword-w {
  width: 120px;
}

.btnwrap {
  justify-content: center;
}

.btn {
  width: 70px;
  padding: 0.2em 0.8em;
  color: #0066b3;
  border-radius: 30rem;
  border: 1px solid #0066b3;
  margin: 1px 3px;
}

.order:hover {
  background-color: #0066b3;
  color: #fff;
}

.self:hover {
  background-color: #0066b3;
  color: #fff;
}

.selfactive {
  background-color: #0066b3;
  color: #fff;
}

.paginatioWrap {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  color: #0066b3;
  font-size: 16px;
}

.updateTime {
  border: 1px solid;
  width: max-content;
  margin-left: auto;
  margin-top: 13px;
  margin-bottom: 3px;
  margin-right: 0;
  border-radius: 50px;
  padding: 0 10px;
  font-size: 12px;
}

.red {
  color: #ff4242;
}

.green {
  color: #0fb992;
}

.margin-top {
  margin-top: 5px;
}

.justify-content-center {
  justify-content: center;
}

.fontSize-22 {
  font-size: 22px;
}

.tableHead-minHeight th>div {
  min-height: 50px !important;
}

.order_btn_display {}


.btn_chosen {
  color: #fff !important;
}

.btn_chosen_sell {
  color: #fff !important;
  background-color: #1d731d !important;
}

.btn_disabled {
  background-color: #e7e7e7 !important;
  color: #bababa !important;
  border: 1px solid #bababa !important;
  cursor: unset !important;
  pointer-events: none !important;
}

.btn_chosen_buy {
  color: #fff !important;
  background-color: #ff3f3f !important;
}

.fivePrice_buy {
  color: #ff3f3f !important;
}

.fivePrice_sell {
  color: #1d731d !important;
}

.fivePrice_normal {
  color: #000 !important;
}

.financialTypeBtn {
  border: 1px solid #bfbfbf;
  border-radius: 8px;
  padding: 5px;
  cursor: pointer;
  background-color: #fff;
  margin: 0 2px;
}

@media screen and (max-width: 410px) {
  .financialTypeBtn {
    margin: 0 1px;
    padding: 5px 0;
  }
}



.stockIcon {
  padding: 2px;
  border: 1px solid #bfbfbf;
  border-radius: 5px;
  margin: 0px 5px;
}

.limitDownType {
  color: #198754;
}

.limitDownType_selected {
  background-color: #198754;
  color: #fff;
}

.openingPriceType {
  color: #212529;
}

.openingPriceType_selected {
  background-color: #5d8edb;
  color: #fff;
}

.limitUpType {
  color: #ff3f3f;

}

.limitUpType_selected {
  background-color: #ff3f3f;
  color: #fff;
}

.marketPriceType {
  color: #f68b1e
}

.marketPriceType_selected {
  background-color: #f68b1e;
  color: #fff;
}

.priceTypeBtn_chosen {
  border: 1px solid #f68b1e;
  background-color: #f68b1e;
  color: #fff !important;
}

.dayTradingBtn_chosen {
  border-radius: 8px;
  color: #fff;
  border: solid 1px #fff;

  background-color: #5d8edb;
}

.boxShadow_none input {
  box-shadow: none !important;
}

.orderTimeRadiosCustomPadding input,
.orderTimeRadiosCustomPadding button {
  padding-left: 16px !important;
}


/* .boxShadow_none .select-search-one__value::after {
  display: none !important;
} */

/* .order_search_wrap {
  border-radius: 5px;
  background-color: #fff;
} */

.order_search_input {
  border-radius: 0 10px 10px 0;

}

.max_height_36px {
  max-height: 36px !important;
}

.order_search_icon {
  padding-left: 5px;
  border-radius: 5px 0 0 5px;
  /* border: 1px solid #dee2e6; */
  border-right: none;
  background-color: #fff;
  border-left: 0;
}

.self_choice_btn {
  padding: 5px;
  background-color: #ffffff;
  color: #9a9a9a;
  /* max-width: 50px; */
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #bfbfbf;
}

.lighting_btn {
  padding: 5px;
  background-color: #d4d4d4;
  color: #9a9a9a;
  /* max-width: 50px; */
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #bfbfbf;
}

.self_choice_btn_selected {
  background-color: #f68b1e;
  padding: 5px;
  color: #ffffff;
  /* max-width: 50px; */
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #fff;
}

.mktDisableInput {
  background-color: #d7d7d7 !important;

}

.max_height_36px>div>div {
  padding: 0 30px 0 0px;
  background-color: #fff;
  border-radius: 5px;
}

.disable_financialType {
  background-color: #e7e7e7;
  color: #bababa;
  cursor: unset;
}