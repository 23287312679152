.text-color {
  color: #131238;
  font-weight: bold;
}

.badge-username {
  background-color: #1B487B !important;
  border-radius: 50px;
  width: 118px;
  height: 30px;
  font-size: 1rem !important;
  color: white !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
  padding: 5px 10px;
}
.updateTime {
  border: 1px solid;
  width: max-content;
  margin-left: auto;
  margin-top: 13px;
  margin-bottom: 3px;
  margin-right: 0;
  border-radius: 50px;
  padding: 0 10px;
  font-size: 12px;
}
.rank_up {
  color: #ff4242;
}
.rank_down {
  color: #0fb992;
}
