.text-color {
  color: #131238;
  font-weight: bold;
}

.badge-username {
  background-color: #1B487B !important;
  border-radius: 50px;
  width: 118px;
  height: 30px;
  font-size: 1rem !important;
  color: white !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
  padding: 5px 10px;
}

.badge-box {
  background-color: #1B487B !important;
  font-size: 1rem !important;
  color: white !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.badge-box-none {
  display: inline-block;
  padding: 0.35em 0;
  font-size: 1rem;
}

.asset-wrap {
  display: flex;
}

.asset-btn {
  min-width: 95px;
  border-radius: 5px;
  padding: 3px 0;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
}

.income {
  background-color: #ff6600;
  border: 1px solid #ff6600;
}

.reset {
  background-color: #bfbfbf;
  border: 1px solid #bfbfbf;
}

.invBtn {
  padding: 1px 0;
  width: 100px;
}

.income:hover {
  background-image: linear-gradient(to bottom,
      #FCB100 0%,
      #FCB100 91%,
      #FCB100 100%);
  background-color: #eb8500;
  border: 1px solid #FCB100;
}

.reset:hover {
  background-color: #afafaf;
  border: 1px solid #afafaf;
}

.tableHeadAction {
  justify-content: center;
  width: 95px;
}

.tableHeadwrap {
  justify-content: center;
  width: 150px;
}

.unit {
  font-size: small;
}

.btn-group {
  display: flex;
}

.btn {
  width: 70px;
  padding: 0.2em 0.8em;
  border-radius: 30rem;
  border: 0;
  margin: 0 3px;
  border: 1px solid;
}

.red {
  color: #ff4242;
}

.btn.red:hover {
  color: #fff;
  background-color: #ff4242;
}

.disabled_btn {
  color: #aaaaaa !important;
}

.disabled_btn:hover {
  color: #aaaaaa !important;
  background-color: transparent !important;
}


.green {
  color: #0fb992;
}

.btn.green:hover {
  color: #fff;
  background-color: #0fb992;
}

.invWarp {
  padding-top: 20px;
}

.share-btn {
  padding: 0 15px !important;
}

.share-wrap {
  display: flex;
  padding-bottom: 20px;
}

.Bell {
  color: #ea9d38;
}

.tip {
  color: #0066b3;
}

.updateTime {
  border: 1px solid;
  width: max-content;
  margin-left: auto;
  margin-top: 13px;
  margin-bottom: 3px;
  margin-right: 0;
  border-radius: 50px;
  padding: 0 10px;
  font-size: 12px;
}

.tableHead-minHeight th>div {
  min-height: 50px !important;
}