.active {
  color: white !important;
  background-color: #0066b3 !important;
  font-weight: bold !important;
}

.dis-active {
  opacity: 0 !important;
}

.disabled {
  color: #c9c9c9 !important;
}

.disabled:hover {
  color: #c9c9c9 !important;
  background-color: inherit !important;
}

.position {
  position: relative;
}

.badge {
  color: orange;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: block;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -10px;
  right: -10px;
  transition: all 0.3s;
}

.verifyActive {
  color: white !important;
  background: linear-gradient(to bottom,
      #53b558 0%,
      #8ace3c 100%) !important;
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  font-weight: bold !important;
  cursor: auto !important;
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
}

.verifyActive:hover {
  color: white !important;
  background: linear-gradient(to bottom,
      #53b558 0%,
      #8ace3c 100%) !important;
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  font-weight: bold !important;
  cursor: auto !important;
}

.noneVerify {
  color: white !important;
  background-image: linear-gradient(to top,
      #ffbf00 0%,
      #ffa03d 81%,
      #ffa03d 100%) !important;
  font-weight: bold !important;
}

.noneVerify:hover {
  color: white !important;
  background-image: linear-gradient(to top,
      #ffbf00 0%,
      #ffa03d 81%,
      #ffa03d 100%) !important;
  font-weight: bold !important;
}

.width-14 {
  width: 14%;
}

.width-95 {
  width: 95%;
}

.btn-padding {
  padding: 0.375rem 0 !important;
}

.minWidth-55px {
  min-width: 55px;
}

.bell-dropshadow {
  filter: drop-shadow(3px 3px 0.2rem rgb(0 0 0/80%))
}

@media (min-width: 768px) and (max-width: 992px) {
  .customizedPadding {
    padding: 0.375rem 0.6rem !important;
  }
}

@media (min-width: 992px) {
  .customizedPadding {
    padding: 0.375rem 1rem !important;
  }
}

.nav_bcc {
  background-color: #ffffff;
}

.pageBtn {
  border-radius: 17px;
  border: solid 1px #00367c !important;
  color: #00367c;
}

.pageBtn:focus {
  box-shadow: none !important;
}