.navbarDisplay {
  left: 50%;
  transform: translateX(-50%);
  top: 6px;
}

.minHeight-content {
  min-height: calc(100vh - 200px);

}

.navBarShowName {
  width: 100%;
  min-height: 55px;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  background-color: #003680;
  color: #fff;
  position: fixed;
  z-index: 10;
}