.text-color {
  color: #131238;
  font-weight: bold;
}

.badge-box {
  background-color: #1B487B !important;
  font-size: 1rem !important;
  color: white !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
}
.updateTime {
  border: 1px solid;
  width: max-content;
  margin-left: auto;
  margin-right: 0;
  margin-top: 13px;
  margin-bottom: 3px;
  border-radius: 50px;
  padding: 0 10px;
  font-size: 12px;
}

.red {
  color: #ff4242;
}

.green {
  color: #0fb992;
}
