table td {
  vertical-align: middle;
  text-align: center;
}

table tbody {
  border-top: 0 !important;
}

.tableHeaderCustom {
  background-color: #1b487b !important;
  color: white !important;
  padding: 5px;
  min-width: 95px;
  border-bottom: black;
}

.tableHeaderCustom-minWidth50 {
  min-width: 50px !important;
  background-color: #1b487b !important;
  color: white !important;
  padding: 2px;
  border-bottom: black;
}

.tableHeaderWarp {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.expander {
  background-color: #1b487b !important;
  color: white !important;
  border-bottom: black;
  width: 20px;
}

.noBorder {
  border: 0 !important;
}

.tableHeaderActionWrap {
  display: flex;
  justify-content: center;
}

.arrow-disable {
  display: none;
}

.arrow-group {
  height: auto;
  width: 10px;
  margin: auto 0;
  padding-left: 5px;
}

.up-arrow {
  width: 0;
  height: 0;
  border: solid 5px transparent;
  background: transparent;
  border-bottom: solid 7px gray;
  border-top-width: 0;
  cursor: pointer;
}

.up-arrow-action {
  border-bottom: solid 7px #fff;
}

.down-arrow {
  width: 0;
  height: 0;
  border: solid 5px transparent;
  background: transparent;
  border-top: solid 7px gray;
  border-bottom-width: 0;
  margin-top: 1px;
  cursor: pointer;
}

.down-arrow-action {
  border-top: solid 7px #fff;
}

.tablebodyCustom {
  text-align: center;
  font-size: 12px;
  font-weight: bolder;
  position: relative;
}

.paginatioWrap {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  font-size: 1rem;
}

.pagination-item {
  border-radius: 5px;
  border: 1px solid #1b487b;
  color: #1b487b;
  font-size: large;
}

.pagination-input {
  width: 4vw;
  text-align: center;
}

.startGame-btn {
  color: #0066b3;
  background-color: #fff;
  border: 1px solid #0066b3;
  border-radius: 20px;
}

.startGame-btn:hover {
  color: #fff;
  background-color: #0066b3;
  border-radius: 20px;
}

.startGame-action {
  color: #fff;
  background-color: #1b487b;
  border-radius: 20px;
}

.hide {
  display: none;
}


.order_table_info {
  max-width: 1200px;
  overflow: scroll;
  margin-bottom: 1rem;
}




.order_table_info::-webkit-scrollbar {
  width: 0;
  height: 16px;
}

.order_table_info::-webkit-scrollbar-corner,
.order_table_info::-webkit-scrollbar-track {
  background-color: rgb(241, 241, 241);
}

.order_table_info::-webkit-scrollbar-thumb {
  background-color: rgb(209, 209, 209);
  background-clip: padding-box;
  border: 2px solid transparent;
}

.order_table_info::-webkit-scrollbar-thumb:hover {
  background-color: rgb(197, 197, 197);
}

.order_table_info::-webkit-scrollbar-thumb:active {
  background-color: rgb(180, 180, 180);
}

/* Buttons */
.order_table_info::-webkit-scrollbar-button:single-button {
  background-color: rgb(241, 241, 241);

  display: block;
  background-size: 10px;
  background-repeat: no-repeat;
}


/* Left */
.order_table_info::-webkit-scrollbar-button:single-button:horizontal:decrement {
  height: 12px;
  width: 12px;
  background-position: 3px 3px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(209, 209, 209)'><polygon points='0,50 50,100 50,0'/></svg>");

}

.order_table_info::-webkit-scrollbar-button:single-button:horizontal:decrement:hover {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(190, 190, 190)'><polygon points='0,50 50,100 50,0'/></svg>");
}

.order_table_info::-webkit-scrollbar-button:single-button:horizontal:decrement:active {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(180, 180, 180)'><polygon points='0,50 50,100 50,0'/></svg>");
}

/* Right */
.order_table_info::-webkit-scrollbar-button:single-button:horizontal:increment {
  height: 12px;
  width: 12px;
  background-position: 3px 3px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(209, 209, 209)'><polygon points='0,0 0,100 50,50'/></svg>");
}

.order_table_info::-webkit-scrollbar-button:single-button:horizontal:increment:hover {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(190, 190, 190)'><polygon points='0,0 0,100 50,50'/></svg>");
}

.order_table_info::-webkit-scrollbar-button:single-button:horizontal:increment:active {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(180, 180, 180)'><polygon points='0,0 0,100 50,50'/></svg>");
}


.whiteBorder::after {
  content: "";
  position: absolute;
  background-color: #fff;
  width: 2px;
  height: 100%;
  top: 0;
  left: 94.7px;
}

.whiteBorderExpand::after {
  content: "";
  position: absolute;
  background-color: #fff;
  width: 2px;
  height: 100%;
  top: 0;
  left: 28px;
}

.table-headerColumn-flex {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.updateTime {
  border: 1px solid;
  width: max-content;
  margin-left: auto;
  margin-top: 13px;
  margin-bottom: 3px;
  margin-right: 0;
  border-radius: 50px;
  padding: 0 10px;
  font-size: 12px;
}